import React from "react"
import styled from "styled-components"

const selectTextSize = props => {
    switch (props.textType) {
        case TextType.Header: {
            return props.theme.headerSize
        }
        case TextType.SubHeader: {
            return props.theme.subHeaderSize
        }
        case TextType.SubSubHeader: {
            return props.theme.subSubHeaderSize
        }
        case TextType.Body: {
            return props.theme.textSize
        }
        case TextType.Small: {
            return props.theme.smallSize
        }
    }
}

const StyledSpan = styled.span`
    font-size: ${props => selectTextSize(props)};
    font-weight: ${props => props.textType === TextType.Header || props.bold ? "bold" : "normal"};
`
export enum TextType {
    Header = "header",
    SubHeader = "subHeader",
    SubSubHeader = "subSubHeader",
    Body = "body",
    Small = "small"
}

interface Props {
    type: TextType
    bold?: boolean
    children: React.ReactNode
}



const Text = (props: Props) => (
    <StyledSpan textType={props.type} bold={props.bold} >
        {props.children}
    </StyledSpan>
)

export default Text
