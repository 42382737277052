const Theme = ({ phone }: { phone?: boolean }) => ({
    main: "#4d6888",
    second: "#dbe1e7",
    third: "#a7b5c5",
    headerSize: phone ? "24px" : "32px",
    subHeaderSize: phone ? "20px" : "28px",
    subSubHeaderSize: phone ? "18px" : "24px",
    textSize: phone ? "16px" : "20px",
    smallSize: "12px",
    textColor: "#373737",
    contentWidth: 1170,
    opacity: 0.8,
    shadow: "4px 6px 6px 1px rgba(0, 0, 0, 0.25)",
    shadowHovered: "4px 6px 10px 2px rgba(0, 0, 0, 0.25)",
    shadowSmooth: "0px 0px 20px 1px lightgrey"
})

export default Theme
